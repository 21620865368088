// styles for small screens and down
@media #{$small-and-down}
{

}

// styles for medium screens and larger
@media #{$medium-and-up}
{

}

// styles for medium screens and down
@media #{$medium-and-down}
{

}

// styles for large screens and up
@media #{$large-and-up}
{

}

// styles for extra large screens and up
@media #{$extra-large-and-up}
{

}

@media (min-width: 1500px)
{

}