.ie9-and-down{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 9999;

    width: 100%;
    background-color: #ccc;
    text-align: center;
    padding: 10px 0px;
    box-shadow: 1px solid #000;
    -ms-box-shadow: 1px solid #000;

    p{
        margin: 0;
        font-size: 12px;
    }

    a{
        color: red;
    }
}
