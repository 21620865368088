.footer-div {
    background-color: #333232;
    padding: 2rem 0rem;

    @media (max-width: 650px) {
        padding-top: 0rem;
        text-align: center;
    }
    .sub-footer {
        font-family: $alegreya-medium;
        font-size: 14px;
        line-height: 14px;
        color: white;
        margin: 0px;
        margin-bottom: 1.5rem;
        text-transform: uppercase;
        @media (max-width: 650px) {
            padding-top: 2rem;
        }
    }
    p {
        font-family: $alegreya-light;
        font-size: 14px;
        line-height: 14px;
        color: white;
        margin: 0px;
        margin-bottom: 12px;
    }
}
.redes-sociales {
    a {
        font-size: 22px;
        display: inline-block;
        color: white;
        margin: 0rem 1rem;
        padding: 2px;
        text-align: center;
        transition: all 300ms;
        &:hover {
            color: $verde;
        }
        &:first-of-type{
            margin-left: 0px;
            @media (max-width: 650px) {
                margin-left: 1rem;
            }
        }
    }
}
.footer {
    padding: 1rem 0rem;
    background-color: #272626;
    // border-bottom: 6px solid $gris-obscuro;
    p {
        font-family: $alegreya-light;
        font-size: 12px;
        line-height: 12px;
        color: white;
        margin: 0rem;
        margin-top: 2px;
    }
    img {
        width: 60px;
        float: right;
        margin-top: -2px;
        @media (max-width: 650px) {
            display: none;
        }
    }
}