$fs-prefix: flex-;
$fe-prefix: -box;

$ctr: center;
$sth: stretch;
$bse: baseline;
$fle: flex-end;
$fls: flex-start;
$spa: space-around;
$spb: space-between;
$col: column;
$row: row;
$wrp: wrap;

@mixin flex_prefix() {
    display: flex;
}

@mixin flex_attribute( $attr, $pos ) {
    #{$attr}: $pos;
}

* {
    &[class*="#{$fs-prefix}"] { @include flex_prefix(); }
    &[class*="#{$fs-prefix}c-"] { @include flex_attribute( align-items, $ctr ); }
    &[class*="#{$fs-prefix}st-"] { @include flex_attribute( align-items, $sth ); }
    &[class*="#{$fs-prefix}b-"] { @include flex_attribute( align-items, $bse ); }
    &[class*="#{$fs-prefix}end"] { @include flex_attribute( align-items, $fle ); }
    &[class*="#{$fs-prefix}start"] { @include flex_attribute( align-items, $fls ); }
    &[class*="-sa#{$fe-prefix}"] { @include flex_attribute( justify-content, $spa ); }
    &[class*="-sb#{$fe-prefix}"] { @include flex_attribute( justify-content, $spb ); }
    &[class*="-fe#{$fe-prefix}"] { @include flex_attribute( justify-content, $fle ); }
    &[class*="-fs#{$fe-prefix}"] { @include flex_attribute( justify-content, $fls ); }
    &[class*="-c#{$fe-prefix}"] { @include flex_attribute( justify-content, $ctr ); }
    &[class*="-end#{$fe-prefix}"] { @include flex_attribute( justify-content, $fle ); }
    &[class*="-col-"] { @include flex_attribute( flex-direction, $col ); }
    &[class*="-row-"] { @include flex_attribute( flex-direction, $row ); }
    &[class*="-wrap-"] { @include flex_attribute( flex-wrap, $wrp ); }
    &[class*="fi-start"] { @include flex_attribute( align-self, $fls ); }
    &[class*="fi-end"] { @include flex_attribute( align-self, $fle ); }
}