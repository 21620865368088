.texto-general {
    p {
        font-family: $alegreya;
        font-size: 18px;
        line-height: 36px;
        color: $gris-obscuro;
        width: 100%;
        margin: 0rem;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
        text-align: justify;
    }
    blockquote {
        border: 0px;
        border-bottom: 1px solid $verde;
        width: 70%;
        padding: 0rem;
        margin-left: auto;
        p {
            font-family: $alegreya-extrabold;
            font-size: 48px;
            line-height: 58px;
            color: $negro;
            width: 100%;
            margin: 0rem;
            padding: 0rem;
            padding-bottom: 8px;
            text-align: left;
        }
    }
}
.img-nosotros {
    text-align: center;
    padding-top: 1rem;
    img {
        width: 80%;
        vertical-align: middle;
        @media (max-width: 650px) {
            width: 100%;
        }
    }
}
.valores-div {
    text-align: center;
    padding-top: 4rem;
    img {
        vertical-align: middle;
        width: 65%;
        margin: 0 auto;
        @media (max-width: 650px) {
            width: 50%;
        }
    }
    h3 {
        font-family: $open-sans-bold;
        font-size: 28px;
        line-height: 34px;
        color: $verde;
        display: block;
        margin: 0rem;
        padding: 0rem;
        padding-top: 2rem;
    }
    hr {
        border: 0px;
        border-bottom: 1px solid $verde;
        width: 50%;
        margin: 1rem auto;
    }
    p {
        font-family: $alegreya;
        font-size: 16px;
        line-height: 24px;
        color: $gris-obscuro;
        margin: 0rem auto;
        width: 85%;
        padding-top: 1rem;
        @media (max-width: 650px) {
            padding-bottom: 3rem;
        }
    }

}
.titulo-servicio {
    text-align: center;
    h2 {
        font-family: $open-sans;
        font-size: 24px;
        line-height: 24px;
        color: $verde;
        margin: 0rem;
        padding: 0rem;
        padding-bottom: 2rem;
        text-decoration: underline;
    }
}
.imagen-servicio {
    text-align: center;
    img {
        width: 50%;
        vertical-align: middle;
        padding-top: 0rem;
        @media (max-width: 1500px) {
            width: 60%;
        }
    }
}
.separador-servicio {
    padding-bottom: 6rem;
}