.owl-propiedad {
    .owl-controls {
        top: calc(50% - 25px);
        .owl-prev, .owl-next {
            img {
                background-color: rgba($verde,0.8);
                @media (max-width: 650px) {
                    width: 40px;
                }
            }
        }
    }
}
.informacion-propiedad {
    padding: 2rem 0rem 0rem 0rem;
    hr {
        border: 0px;
        border-bottom: 1px solid $verde;
        margin-top: 1.4rem;
        margin-bottom: 1.5rem;
    }
    .direccion-propiedad {
        padding-left: 2rem;
        @media (max-width: 650px) {
            padding-left: 0rem;
        }
        p {
            font-family: $alegreya-medium;
            font-size: 18px;
            line-height: 24px;
            color: $negro;
            margin: 0rem;
            &:first-of-type {
                font-family: $alegreya-bold;
                font-size: 20px;
                line-height: 24px;
            }
        }
    }
    .precio-propiedad {
        font-family: $alegreya-bold;
        font-size: 24px;
        line-height: 24px;
        color: $verde;
        margin: 0rem;
        padding: 1rem 2rem;
        display: inline-block;
        background-color: #f2f2f2;
    }
    .texto-general {
        padding-top: 1rem;
        padding-left: 2rem;
        @media (max-width: 650px) {
            padding-left: 0rem;
        }
        p {
            line-height: 30px;
        }
    }
    .caracteristicas-propiedad {
        padding: 1rem 2rem;
        overflow: hidden;
        @media (max-width: 650px) {
            padding: 1rem 0rem;
        }
        p{
            width: 100%;
            font-family: $alegreya;
            font-size: 24px;
            line-height: 24px;
            color: $negro;
            margin: 0px;
            margin-bottom: 1.5rem;
            img {
                width: 46px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 6px
            }
        }
    }
    .compartir-propiedad {
        border-top: 1px solid $verde;
        border-bottom: 1px solid $verde;
        padding: 1rem 0rem;
        padding-left: 2rem;
        @media (max-width: 650px) {
            padding-left: 0rem;
        }
        p {
            font-family: $alegreya;
            font-size: 20px;
            line-height: 20px;
            color: $negro;
            margin: 0px;
            margin-right: 2rem;
            display: inline-block;
            text-transform: uppercase;
        }
        a {
            font-size: 18px;
            display: inline-block;
            color: $verde;
            margin: 0rem 8px;
            padding: 2px;
            text-align: center;
            transition: all 300ms;
            &:hover {
                color: $verde-obscuro;
            }
        }
    }
}
.titulo-formulario {
    font-family: $alegreya-medium;
    font-size: 26px;
    line-height: 26px;
    color: $verde;
    margin: 0px;
    margin-top: 6rem;
    margin-bottom: 2rem;
    // border-bottom: 1px solid $verde;
    text-transform: uppercase;
    padding-left: 2rem;
    @media (max-width: 650px) {
        padding-left: 0rem;
    }
}
.contact-form-home {
    width: 100%;
    margin: 0;
    margin-right: auto;
    padding-left: 22px;
    @media (max-width: 650px) {
        width: 100%;
        padding-left: 0rem;
    }
    input, .input-form, input[type=text]:not(.browser-default), input[type=email]:not(.browser-default) {
        border: 1px solid #afafaf;
        background-color: white !important;
        float: left;
        width: 48% !important;
        height: 45px !important;
        margin: 0rem 1%;
        margin-bottom: 20px !important;
        padding: 0rem 0.5rem !important;
        box-sizing: border-box !important;
        color: $verde-obscuro;
        outline: none;
        font-family: $open-sans;
        font-size: 14px;
        line-height: 14px;
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $verde !important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $verde !important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $verde !important;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $verde !important;
        }
        @media (max-width: 650px) {
            width: 100% !important;
            margin-left: 0rem;
            margin-right: 0rem;
        }
    }
    input[type="submit"] {
        width: 120px !important;
        height: 40 !important;
        padding: 10px 20px !important;
        background-color: $verde !important;
        border: 1px solid $verde !important;
        border-radius: 0px !important;
        box-sizing: border-box !important;
        font-family: $open-sans;
        font-size: 12px !important;
        line-height: 12px !important;
        color: white !important;
        // text-transform: uppercase !important;
        transition: all 300ms !important;
        cursor: pointer;
        float: none;
        &:hover {
            background-color: $verde-obscuro !important;
            border: 1px solid $verde-obscuro !important;
            color: white !important;
        }

    }
    textarea {
        border: 1px solid #afafaf;
        background-color: white;
        padding: 0.5rem;
        width: 98%;
        height: 175px;
        box-sizing: border-box !important;
        margin: 0rem 1%;
        margin-bottom: 1rem;
        color: $verde-obscuro;
        outline: none;
        font-family: $open-sans;
        font-size: 14px;
        line-height: 14px;
        @media (max-width: 650px) {
            width: 100%;
            margin-left: 0rem;
            margin-right: 0rem;
        }
        &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
          color: $verde !important;
        }
        &::-moz-placeholder { /* Firefox 19+ */
          color: $verde !important;
        }
        &:-ms-input-placeholder { /* IE 10+ */
          color: $verde !important;
        }
        &:-moz-placeholder { /* Firefox 18- */
          color: $verde !important;
        }
    }
}
.precio-align {
    text-align: right;
    @media (max-width: 650px) {
        text-align: left;
        margin-top: 1rem;
    }
}
