@mixin placeholder($Color){
    ::-webkit-input-placeholder { color: $Color;  }
    ::-moz-placeholder { color: $Color;  }
    :-ms-input-placeholder { color: $Color;  }
    input:-moz-placeholder { color: $Color;  }
}

@mixin imag($Width){
    max-width: $Width;
    width: 100%;
    display: block;
    margin: 0 auto;
}

@mixin itransition($prop: all, $time: 0.4s , $animation: ease){
    -webkit-transition: $prop  $time $animation;
    -moz-transition:    $prop  $time $animation;
    transition:         $prop  $time $animation;
}

@mixin animate($property: opacity, $type: linear, $Duration: 0.2s, $Delay: 0.1s){
    -webkit-transition-property: $property;
    -moz-transition-property:    $property;
    -ms-transition-property:     $property;
    transition-property:         $property;

    -webkit-transition-duration: $Duration;
    -moz-transition-duration:    $Duration;
    -ms-transition-duration:     $Duration;
    transition-duration:         $Duration;

    -webkit-transition-timing-function: $type;
    -moz-transition-timing-function:    $type;
    -ms-transition-timing-function:     $type;
    transition-timing-function:         $type;

    -webkit-transition-delay: $Delay;
    -moz-transition-delay:    $Delay;
    -ms-transition-delay:     $Delay;
    transition-delay:         $Delay;
}


//Backgrounds
@mixin bg_img($bg,$repeat: no-repeat, $position: center center){
    background: url('#{$root}#{$bg}') $repeat $position;
}


@mixin text_shadow($x: 1px, $y: 1px , $z: 1px){
    -webkit-text-shadow: $x $y $z $Black;
    -moz-text-shadow:    $x $y $z $Black;
    -ms-text-shadow:     $x $y $z $Black;
    text-shadow:         $x $y $z $Black;
}


.pagi{
    color: $Black;
    width: 23px;
    height: 30px;
    background-color: $White;
    text-align: center;
    font-size: 30px;
    margin-top: -5px;
}

@mixin controls_owl($prev: 'left.png',$next: 'right.png',$Left: 10px,$Right: 10px){
    .owl-controls{
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;

        .owl-nav{

            .owl-prev{ @extend pagi;
                background: url('#{$root}/icon/#{$prev}') no-repeat center center;
                float: left;
                margin-left: $Left;
            }
            .owl-next{ @extend pagi;
                background: url('#{$root}/icon/#{$next}') no-repeat center center;
                float: right;
                margin-right: $Right;
            }
        }

        .owl-dots{
            text-align: center;

            .active{
                background-color: $Black !important;
            }
            .owl-dot{
                background-color: $White;
                width: 15px;
                height: 15px;
                display: inline-block;
                border-radius: 50%;
                margin: 0 5px;
            }
        }
    }
}

@mixin keyframe($name: name, $duration: 1s, $count: infinite, $animation: ease){
    -webkit-animation-name: $name;
    -webkit-animation-duration: $duration;
    -webkit-animation-iteration-count: $count;

    animation-name: $name;
    animation-duration: $duration;
    animation-iteration-count: $count;
    animation-timing-function: $animation;
}

@mixin radius($Radio: 5px){
    border-radius: $Radio;
}
@mixin radius_left($Radio: 5px){
    border-top-left-radius: $Radio;
    border-bottom-left-radius: $Radio;
}
@mixin radius_right($Radio: 5px){
    border-top-right-radius: $Radio;
    border-bottom-right-radius: $Radio;
}
@mixin radius_top($Radio: 5px){
    border-top-left-radius: $Radio;
    border-top-right-radius: $Radio;
}
@mixin radius_bottom($Radio: 5px){
    border-bottom-left-radius: $Radio;
    border-bottom-right-radius: $Radio;
}

@mixin radius_bottom_left($Radio: 5px){
    border-bottom-left-radius: $Radio;
}

@mixin scale($x: 1.05, $y: 1.05) {
    -webkit-transform: scale($x, $y);
    -moz-transform: scale($x, $y);
    -ms-transform: scale($x, $y);
    transform: scale($x, $y);
}

@mixin rotate($deg: 0deg) {
    -webkit-transform: rotate($deg);
    -moz-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin boxshadow($shadow: 1px 1px 3px #000) {
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -ms-box-shadow: $shadow;
    box-shadow: $shadow;
}

//Gradient from top to bottom
@mixin gradient_stops($top, $center, $bottom){
    background: $center;
    background: -webkit-linear-gradient($top, $center, $bottom);
    background: -o-linear-gradient($top, $center, $bottom);
    background: -moz-linear-gradient($top, $center, $bottom);
    background: linear-gradient($top, $center, $bottom);
}

//Gradient width grades
@mixin gradient_deg($deg, $left, $right){
    background: $right;
    background: -webkit-linear-gradient($deg, $left, $right);
    background: -o-linear-gradient($deg, $left, $right);
    background: -moz-linear-gradient($deg, $left, $right);
    background: linear-gradient($deg, $left, $right);
}

@mixin triangulo-equilatero-bottom-left($Color: #f0ad4e) {
     width: 0;
     height: 0;
     border-right: 25px solid transparent;
     border-top: 25px solid transparent;
     border-left: 25px solid $Color;
     border-bottom: 25px solid $Color;
}

@mixin triangulo-equilatero-top-left($Color: #f0ad4e) {
     width: 0;
     height: 0;
     border-top: 25px solid $Color;
     border-left: 25px solid $Color;
     border-right: 25px solid transparent;
     border-bottom: 25px solid transparent;
}

@mixin triangulo-equilatero-top-right($Color: #f0ad4e) {
     width: 0;
     height: 0;
     border-top: 25px solid $Color;
     border-right: 25px solid $Color;
     border-left: 25px solid transparent;
     border-bottom: 25px solid transparent;
}
