.fondo-banner {
    position: relative;
    img {
        vertical-align: middle;
    }
}
.layer-black {
    background-color: rgba(black,0.4);
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
}
.titulo-banner {
    position: absolute;
    width: 70%;
    bottom: 3.5rem;
    left: 50%;
    -ms-transform: translateX(-50%); /* IE 9 */
    -webkit-transform: translateX(-50%); /* Chrome, Safari, Opera */
    transform: translateX(-50%);
    text-align: center;
    @media (max-width: 650px) {
        width: 90%;
    }
    p {
        font-family: $open-sans-light;
        font-size: 76px;
        line-height: 80px;
        color: white;
        text-shadow: 2px 2px 2px black;
        text-transform: uppercase;
        margin: 0rem;
        padding: 0rem;
        display: inline-block;
        text-align: center;
        position: relative;
        span {
            font-family: $open-sans-light-italic;
            font-size: 46px;
            line-height: 46px;
            color: white;
            margin: 0rem;
            // padding-right: 10%;
            text-shadow: 2px 1px 1px black;
            text-transform: initial;
            position: absolute;
            display: block;
            top: -46px;
            right: 64px;
        }
        &:before {
            content: "";
            display: block;
            background-color: $verde;
            width: 36px;
            height: 12px;
            float: left;
            margin: 32px 2rem 0 0;
        }
        &:after {
            content: "";
            display: block;
            background-color: $verde;
            width: 36px;
            height: 12px;
            float: right;
            margin: 32px 0 0 2rem;
        }
        @media (max-width: 650px) {
            font-size: 42px;
            line-height: 42px;
            span {
                font-size: 32px;
                line-height: 32px;
                top: -36px;
            }
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
        // &:first-of-type {
        //     font-family: $open-sans-light-italic;
        //     font-size: 46px;
        //     line-height: 46px;
        //     color: white;
        //     margin: 1rem 0rem;
        //     padding-right: 10%;
        //     text-align: right;
        //     text-shadow: 2px 1px 1px #3c3c3c;
        //     text-transform: initial;
        // }
    }
}
.fondo-gris {
    background-color: #f2f2f2;
    padding: 4rem 0rem;
}
.contact-form {
    width: 80%;
    margin: 0 auto;
    padding: 0rem;
    @media (max-width: 650px) {
        width: 100%;
    }
    label {
        font-family: $open-sans;
        font-size: 16px;
        line-height: 16px;
        color: $verde;
        display: block;
        margin-bottom: 8px;
    }
    input {
        border: 1px solid #afafaf;
        background-color: white;
        width: 100%;
        height: 45px;
        padding: 0rem 0.5rem;
        box-sizing: border-box !important;
        color: $verde-obscuro;
        // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        //   color: $verde !important;
        // }
        // &::-moz-placeholder { /* Firefox 19+ */
        //   color: $verde !important;
        // }
        // &:-ms-input-placeholder { /* IE 10+ */
        //   color: $verde !important;
        // }
        // &:-moz-placeholder { /* Firefox 18- */
        //   color: $verde !important;
        // }
    }
    input[type="submit"] {
        width: 150px;
        display: inline-block;
        background-color: $verde; /* For browsers that do not support gradients */
        // background: linear-gradient(to right, $verde , $verde);
        border: 0px solid $verde;
        font-family: $open-sans-light;
        font-size: 14px;
        line-height: 14px;
        color: white;
        transition: all 300ms;
        &:hover {
            background-color: $verde-obscuro;
            // background: linear-gradient(to right, $verde , $verde);
        }
    }
    textarea {
        border: 1px solid #afafaf;
        background-color: white;
        padding: 0.5rem;
        width: 100%;
        height: 200px;
        box-sizing: border-box !important;
        margin-bottom: 1rem;
        color: $verde-obscuro;
        // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        //   color: $verde !important;
        // }
        // &::-moz-placeholder { /* Firefox 19+ */
        //   color: $verde !important;
        // }
        // &:-ms-input-placeholder { /* IE 10+ */
        //   color: $verde !important;
        // }
        // &:-moz-placeholder { /* Firefox 18- */
        //   color: $verde !important;
        // }
    }
    .error {
        border: 1px solid $Red;
    }
}
.info-contacto {
    padding: 4rem 0rem;
    background-color: $verde-claro;
    border-top: 1px solid $verde-obscuro;
    // border-bottom: 1px solid $verde-obscuro;
    margin-top: 0rem;
    @media (max-width: 650px) {
        text-align: center;
        padding-top: 2rem;
    }
    .sub-contacto {
        font-family: $open-sans-bold;
        font-size: 22px;
        line-height: 22px;
        color: $verde-obscuro;
        text-transform: uppercase;
        padding-bottom: 1rem;
        padding-top: 1rem;
        i {
            float: left;
            margin-top: -2px;
            margin-right: 6px;
            @media (max-width: 650px) {
                float: none;
            }
        }
    }
    p {
        font-family: $open-sans;
        font-size: 14px;
        line-height: 16px;
        color: $verde-obscuro;
        margin: 0px;
        margin-bottom: 16px;
        padding-right: 1rem;
        @media (max-width: 650px) {
            padding-left: 0rem;
        }
    }
    .separador-02 {
        width: 70%;
        margin-right: auto;
        margin-bottom: 3rem;
        height: 2px;
        @media (max-width: 650px) {
            margin-left: auto;
        }
    }
    .redes-contacto {
        a {
            display: inline-block;
            font-size: 30px;
            color: $verde-obscuro;
            margin: 0rem 1rem;
            padding: 5px;
            padding-top: 0px;
            text-align: center;
            transition: all 300ms;
            &:hover {
                color: $verde;
            }
            &:first-of-type{
                margin-left: 0px;
            }
        }
    }
}
#mapa-div {
    height: 500px;
    overflow: hidden;
    @media (max-width: 1500px) {
        height: 500px;
    }
}