.container {
    width: 70%;
    @media (max-width: 650px) {
        width: 90%;
    }
}
.titulo-slide {
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%); /* IE 9 */
    -webkit-transform: translate(-50%,-50%); /* Chrome, Safari, Opera */
    transform: translate(-50%,-50%);
    p {
        font-family: $open-sans-light-italic;
        font-size: 46px;
        line-height: 46px;
        color: white;
        margin: 1rem 0rem;
        padding-right: 10%;
        text-align: right;
        text-shadow: 2px 1px 1px #3c3c3c;
        @media (max-width: 650px) {
            font-size: 42px;
            line-height: 42px;
        }
    }
    p:first-of-type {
        font-size: 28px;
        line-height: 28px;
        padding-right: 20%;
    }
    h2 {
        font-family: $open-sans-light;
        font-size: 90px;
        line-height: 80px;
        color: white;
        text-shadow: 2px 2px 2px black;
        text-transform: uppercase;
        &:before {
            content: "";
            display: block;
            background-color: $verde;
            width: 36px;
            height: 12px;
            float: left;
            margin: 32px 2rem 0 0;
        }
        &:after {
            content: "";
            display: block;
            background-color: $verde;
            width: 36px;
            height: 12px;
            float: right;
            margin: 32px 0 0 2rem;
        }
        @media (max-width: 1025px) {
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
        @media (max-width: 650px) {
            font-size: 42px;
            line-height: 42px;
            &:before {
                display: none;
            }
            &:after {
                display: none;
            }
        }
    }
    @media (max-width: 650px) {
        width: 70%;
        font-size: 40px;
        line-height: 48px;
        text-align: center;
    }
}
.owl-home {
    .owl-controls {
        top: calc(49% - 25px);
        .owl-prev, .owl-next {
            img {
                background-color: rgba($verde,0.8);
                vertical-align: middle;
                @media (max-width: 650px) {
                    width: 44px;
                }
            }
        }
    }
}
.owl-propiedades-destacadas {
    .owl-controls {
        top: calc(49% - 25px);
        .owl-prev, .owl-next {
            img {
                background-color: rgba($verde,0.8);
                vertical-align: middle;
            }
        }
    }
}
.tra-izq {
    position: absolute;
    top: 0%;
    left: 0px;
    width: 400px !important;
}
.tra-der {
    position: absolute;
    bottom: 0%;
    right: 0px;
    width: 220px !important;
}
.trap-izq-detalle {
    position: absolute;
    top: 10rem;
    left: 0px;
    width: 165px !important;
    @media (max-width: 650px) {
        display: none;
    }
}
.trap-der-detalle {
    position: absolute;
    bottom: 15%;
    right: 0px;
    width: 175px !important;
    @media (max-width: 650px) {
        display: none;
    }
}
.pad-container {
    padding-top: 4rem;
    padding-bottom: 4rem;
}
.subtitulo-general {
    font-family: $roboto-bold;
    font-size: 28px;
    line-height: 30px;
    color: $gris-obscuro;
    margin: 0px auto;
    margin-bottom: 3rem;
    padding: 1.5rem 0rem;
    border-bottom: 1px solid $gris-obscuro;
    text-align: center;
    text-transform: uppercase;
    width: 65%;
    @media (max-width: 650px) {
        width: 85%;
    }
}
.texto-home {
    p {
        font-family: $roboto-light;
        font-size: 18px;
        line-height: 36px;
        color: $gris-obscuro;
        width: 85%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 1rem;
    }
}

.espacio-pro {
    padding-bottom: 3rem !important;
}
.fondo-gris {
    background-color: #f2f2f2;
}



.cont-prop-destacadas, .contacts {
    padding: 4rem 0rem;
    background-color: #f5f8f4;
    @media (max-width: 650px) {
        padding: 3rem 0rem;
    }
}
.cont-des-destacadas {
    padding: 4rem 0rem;
    background-color: white;
    @media (max-width: 650px) {
        padding: 3rem 0rem;
    }
}
.propiedad-owl {
    overflow: hidden;
    .foto-prop {
        width: 40%;
        height: 100%;
        position: absolute;
        background-size: cover;
        background-position: 50%;
        @media (max-width: 650px) {
            width: 100%;
            height: 250px;
            position: static;
        }
    }
    .info-prop {
        width: 60%;
        float: right;
        border: 1px solid $verde;
        border-left: 0px;
        @media (max-width: 650px) {
            width: 100%;
            border: 1px solid $verde;
        }
        .info {
            padding: 1rem 2rem;
            p {
                font-family: $alegreya-medium;
                font-size: 14px;
                line-height: 14px;
                color: #333232;
                margin: 0px;
                margin-bottom: 5px;
            }
        }
        .detalles {
            padding: 1rem 2rem;
            overflow: hidden;
            p{
                width: 50%;
                float: left;
                font-family: $alegreya;
                font-size: 12px;
                line-height: 12px;
                color: #333232;
                margin: 0px;
                margin-bottom: 10px;
                img {
                    width: 24px;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: 6px
                }
            }
        }
        .detalles-desarrollo {
            p {
                width: 100%;
            }
        }
        .precio {
            background-color: #f2f2f2;
            font-family: $alegreya-bold;
            font-size: 18px;
            line-height: 18px;
            color: $verde;
            text-transform: uppercase;
            margin: 0px;
            padding: 0.6rem 2rem;
        }
        .redes {
            background-color: #f2f2f2;
            margin: 0px;
            padding: 0.6rem 2rem;
            font-family: $alegreya-medium;
            font-size: 14px;
            line-height: 12px;
            color: #333232;
            margin: 0px;
            text-transform: uppercase;
        }
    }
}
.cont-servicios {
    padding: 4rem 0rem;
    background-color: #f0f0f5;
    @media (max-width: 650px) {
        padding: 3rem 0rem;
    }
    img {
        max-width: 230px;
        margin-bottom: 1rem;
        @media (max-width: 650px) {
            margin-bottom: 0rem;
        }
    }
    h4 {
        font-family: $alegreya-medium;
        font-size: 28px;
        line-height: 28px;
        color: $verde-obscuro;
        text-transform: uppercase;
    }
    p {
        font-family: $alegreya-light;
        font-size: 20px;
        line-height: 26px;
        color: $gris-obscuro;
        text-align: center;
        @media (max-width: 650px) {
            margin-bottom: 1.5rem;
            text-align: center;
        }
    }
    .saber-mas {
        @media (max-width: 650px) {
            margin-bottom: 4rem;
        }
    }
}
.cont-serviadmin {
    padding: 4rem 0rem;
    background-color: white;
    @media (max-width: 650px) {
        padding: 3rem 0rem;
    }
    img {
        max-width: 230px;
        margin-bottom: 1rem;
        @media (max-width: 650px) {
            margin-bottom: 0rem;
        }
    }
    span{
        font-size: 60px;
        color: $verde;
    }
    h4 {
        font-family: $alegreya-medium;
        font-size: 23px;
        line-height: 28px;
        color: $verde-obscuro;
    }
    p {
        font-family: $alegreya-light;
        font-size: 20px;
        line-height: 26px;
        color: $gris-obscuro;
        text-align: center;
        @media (max-width: 650px) {
            margin-bottom: 1.5rem;
            text-align: center;
        }
    }
    .saber-mas {
        @media (max-width: 650px) {
            margin-bottom: 4rem;
        }
    }
}
.subtitulo-home {
    font-family: $alegreya-medium;
    font-size: 30px;
    line-height: 30px;
    color: $gris-obscuro;
    margin: 0px auto;
    padding-bottom: 1rem;
    text-transform: uppercase;
    text-align: center;
    width: 100%;
    @media (max-width: 650px) {
        width: 85%;
        border-bottom: 1px solid $verde-obscuro;
    }
    &:before {
        background:url('../images/icon/linea.svg');
        content: "";
        display: inline-block;
        width: 150px;
        height: 1px;
        vertical-align: middle;
        margin-right: 2rem;
        @media (max-width: 769px) {
            margin: 0rem;
            background-size: 100%;
            display: none;
        }
    }
    &:after {
        background:url('../images/icon/linea.svg');
        content: "";
        display: inline-block;
        width: 150px;
        height: 1px;
        vertical-align: middle;
        margin-left: 0.5rem;
        @media (max-width: 769px) {
            margin: 0rem;
            background-size: 100%;
            display: none;
        }
    }
}
.saber-mas {
    display: block;
    font-family: $alegreya-medium;
    font-size: 14px;
    line-height: 14px;
    color: $verde-obscuro;
    text-decoration: underline !important;
    text-transform: uppercase;
    text-align: center;
}
.btn-verde {
    font-family: $alegreya-light;
    font-size: 14px;
    line-height: 14px;
    color: white;
    background-color: $verde-obscuro !important;
    border: none;
    text-decoration: none;
    padding: 1rem 2.5rem !important;
    display: inline-block !important;
    transition: 300ms all;
    &:hover {
        color: white;
        background-color: #131313 !important;
    }
}
.btn-green {
    font-family: $alegreya-medium;
    font-size: 14px;
    line-height: 14px;
    color: white;
    background-color: $verde !important;
    border: none;
    text-decoration: none;
    padding: 1rem 2.5rem !important;
    display: inline-block !important;
    transition: 300ms all;
    &:hover {
        color: white;
        background-color: $verde-obscuro !important;
    }
}
.btn-gris {
    font-family: $alegreya-medium;
    font-size: 14px;
    line-height: 14px;
    color: white;
    background-color: #707176 !important;
    border: none;
    text-decoration: none;
    padding: 1rem 2.5rem !important;
    display: inline-block !important;
    transition: 300ms all;
    &:hover {
        color: white;
        background-color: #333333 !important;
    }
}
.btn-arriba {
    padding-top: 3rem !important;
}
.fondo-newsletter {
    background-color: #cce5bb;
    h3 {
        font-family: $alegreya-italic;
        font-size: 28px;
        line-height: 28px;
        color: $verde-obscuro;
        border-bottom: 1px solid $verde-obscuro;
        margin: 0px auto;
        padding-bottom: 1rem;
        text-transform: uppercase;
        text-align: center;
        width: 65%;
    }
    p {
        font-family: $alegreya-light;
        font-size: 22px;
        line-height: 22px;
        color: $verde-obscuro;
        margin: 1rem 0rem;
    }
    form {
        width: 70%;
        margin: 0 auto;
        padding-top: 2rem;
        @media (max-width: 650px) {
            width: 100%;
        }
        input {
            border: 1px solid $verde-obscuro;
            background-color: white;
            font-family: $roboto-light;
            font-size: 14px;
            line-height: 14px;
            color: $gris-obscuro;
            margin-top: 0rem;
            margin-bottom: 2rem;
            margin-right: 1.5rem;
            text-align: center;
            padding: 0rem 2rem;
            box-sizing: border-box;
            width: 65%;
            @media (max-width: 650px) {
                width: 100%;
            }
        }
    }
    .btn-verde{
        width: 20%;
        @media (max-width: 650px) {
            width: auto;
        }
    }
}