@import "materialize";

//VARIABLES
$screen-bg-min: 1500px;

$White      : #fff;
$Black      : #000;

$Facebook   : #3b5998;
$Twitter    : #4099FF;
$Instagram  : #125688;
$Pinterest  : #A9262A;
$Vimeo      : #44BBFF;
$Linkedin   : #0077B5;
$Youtube    : #e52d27;
$Red        : #e52d27;

$Ocho       : #888888;
$Gris       : #292929;
$GrisFuerte : #4c4b4b;
$GrisClaro  : #676767;
$Rosa       : #fe1021;

$gris-obscuro: #707176;
$negro: #333232;
$azul: #1e4c6d;
$verde: #92c77f;
$verde-claro: #cce5bb;
$verde-obscuro: #43563b;

$primary    : $Black;
$secundary  : $Black;
$third      : $Black;

$root: "../images/";

@import "mixes";
@import "helps";
@import "flex";
@import "fonts";

$roboto-thin: "Roboto Thin";
$roboto-light: "Roboto Light";
$roboto: "Roboto";
$roboto-medium: "Roboto Medium";
$roboto-bold: "Roboto Bold";
$roboto-black: "Roboto Black";

$open-sans: "Open Sans";
$open-sans-light: "Open Sans Light";
$open-sans-light-italic: "Open Sans Light Italic";
$open-sans-bold: "Open Sans Bold";
$open-sans-extrabold: "Open Sans Extrabold";

$alegreya-light: "Alegreya Sans Light";
$alegreya-italic: "Alegreya Sans Italic";
$alegreya: "Alegreya Sans";
$alegreya-medium: "Alegreya Sans Medium";
$alegreya-bold: "Alegreya Sans Bold";
$alegreya-extrabold: "Alegreya Sans Extrabold";


// Plantillas

.controls_dots{
  .owl-controls{ position: absolute; bottom: -30px; left: 0; width: 100%;
    .owl-dots{ text-align: center;
      .active{ background-color: $Black !important; background-position: center -4px !important; }
      .owl-dot{ background-color: #ccc; background-position: center bottom;  width: 10px; height: 10px; display: inline-block; border-radius: 50%; margin: 0 5px; }
    }
  }
}

// Backgrounds ==================================================

.bg_black{ background-color: $Black; }
.bg_white{ background-color: $White; }

.facebook{
  background-color: $Facebook;
  color: $White;
}
.twitter{
  background-color: $Twitter;
  color: $White;
}
.instagram{
  background-color: $Instagram;
  color: $White;
}
.pinterest{
  background-color: $Pinterest;
  color: $White;
}
.vimeo{
  background-color: $Vimeo;
  color: $White;
}
.linkedin{
  background-color: $Linkedin;
  color: $White;
}


// Colors ========================================================
.whatsapp-container{
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: #00e676;
    border-radius: 100%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all .4s;
    cursor: pointer;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.14), 0 6px 5px 2px rgba(0,0,0,.12), 0 1px 5px 0 rgba(0,0,0,.2);
    svg{
        font-size: 2.5em;
        color: #fff!important;
    }
} 

//Botones =======================================================

.button{
  @include itransition;
  background-color: $Black;
  color: $White;
  text-align: center;
  padding: 10px 20px;
  font-size: 14px;
  margin: 5px 0px;
  border: 0;
  display: inline-block;

  &:hover{
    color: $White;
    background-color: #383636;
  }
}

//Formas ========================================================
.form{
  @include placeholder(#767676);
  .form-control{
    border-radius: 0;
    border-color: #e1e1e1;
    height: 50px;
    color: #767676;
  }
  textarea{
    height: inherit !important;
  }
}
.form-newsletter{
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;

  input[type="email"]{
    background: none;
  }

  button{
    background-color: #ccc;
    border: 0;
    padding: 10px 10px;
  }
}

///*************** CONTENIDO **************************************************/
.share-slim{
  max-width: 380px;
  display: block;
  margin: 15px auto;
}

.line{
  width: 110px;
  display: block;
  margin: 10px auto;
  border-bottom: 2px solid $Black;
}

.socials{
  a{
    @include itransition;
    color: $White;
    font-size: 20px;
    display: inline-block;
    padding: 0px 10px;

    &:hover{
      color: $Black;
    }
  }
}

.visible-desktop{ display: inherit; }
.visible-tablet{ display: none; }
.visible-mobile{ display: none; }

.modal-xs{
  max-width: 600px;
}

.privacy{
  color: $Gris;
  &:hover{
    color: $primary;
  }
}

// ****************************** BODY ***************************************//
// body,html{ height: 100%; } //Activar si solo si es fullscreen
a{
  color: $Black;
  text-decoration: none !important;
  outline: none;
  &:hover{
    color: $Gris;
  }
}
body{
  min-width: 320px !important;
  color: $Black;
  line-height: 1.4;
}

.arrow{
  position: absolute;
  top: 25%;
  z-index: 15;
  display: block;
}

.arrow-left{
  left: 10px;
}

.arrow-right{
  right: 10px;
}


.slider_home{
  overflow: hidden;

  .transition_one{
    text-align: center !important;
    top: 20% !important;
    padding: 1rem !important;
    height: auto !important;
    width: 100%;

    .title{
      color: $White;
      line-height: 1;
      font-size: 1rem;
    }
  }
}

//*-------------------------------------------------------------------------------------*//
//* Index
//*-------------------------------------------------------------------------------------*//
    .row {
        margin-bottom: 0rem;
    }
    .img-100 {
        width: 100%;
    }
    .relative {
        position: relative;
    }
    .overflow {
        overflow: hidden;
    }
    .transparente {
        color: transparent !important;
    }
    .text-center {
        text-align: center;
    }
    .margin-auto {
        margin: 0 auto;
    }
    .uno-arriba {
        padding-top: 16px;
    }
    .dos-arriba {
        padding-top: 32px;
    }
    .tres-arriba {
        padding-top: 48px;
    }
    .cuatro-arriba {
        padding-top: 64px;
    }
    .uno-abajo {
        padding-bottom: 16px;
    }
    .dos-abajo {
        padding-bottom: 32px;
    }
    .tres-abajo {
        padding-bottom: 48px;
    }
    .cuatro-abajo {
        padding-bottom: 64px;
    }
    .m-dos-arriba {
      margin-top: 2rem;
    }
    .placeholder-menu {
      width: 100%;
      height: 80px;
      background-color: white;
    }
.owl-propiedades-destacadas{
  display: flex;
  flex-wrap: wrap;
}
.propiedad-owl{ 
  position: relative; margin-bottom: 2rem; 
  .info-prop{
    min-height: 254px;
  }
}
@import "slider";
@import "header";
@import "async";
@import "home";
@import "nosotros";
@import "propiedad";
@import "detalle";
@import "contacto";
@import "footer";
@import "ie9";
@import "responsive";
