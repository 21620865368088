@mixin arial_regular($size: 1rem){
    font-family: "arial";
    font-size: $size;
}

@font-face {
    font-family:'Roboto Thin';
    src: url('../fonts/roboto/Roboto Thin.eot');
    src: url('../fonts/roboto/Roboto Thin.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Thin.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Thin.woff') format('woff'),
        url('../fonts/roboto/Roboto Thin.otf') format('opentype'),
        url('../fonts/roboto/Roboto Thin.svg#Roboto Thin') format('svg');
    font-weight: 250;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Roboto Light';
    src: url('../fonts/roboto/Roboto Light.eot');
    src: url('../fonts/roboto/Roboto Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Light.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Light.woff') format('woff'),
        url('../fonts/roboto/Roboto Light.otf') format('opentype'),
        url('../fonts/roboto/Roboto Light.svg#Roboto Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Roboto';
    src: url('../fonts/roboto/Roboto.eot');
    src: url('../fonts/roboto/Roboto.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto.woff2') format('woff2'),
        url('../fonts/roboto/Roboto.woff') format('woff'),
        url('../fonts/roboto/Roboto.otf') format('opentype'),
        url('../fonts/roboto/Roboto.svg#Roboto') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
}

@font-face {
    font-family:'Roboto Medium';
    src: url('../fonts/roboto/Roboto Medium.eot');
    src: url('../fonts/roboto/Roboto Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Medium.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Medium.woff') format('woff'),
        url('../fonts/roboto/Roboto Medium.otf') format('opentype'),
        url('../fonts/roboto/Roboto Medium.svg#Roboto Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Roboto Bold';
    src: url('../fonts/roboto/Roboto Bold.eot');
    src: url('../fonts/roboto/Roboto Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Bold.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Bold.woff') format('woff'),
        url('../fonts/roboto/Roboto Bold.otf') format('opentype'),
        url('../fonts/roboto/Roboto Bold.svg#Roboto Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}


@font-face {
    font-family:'Roboto Black';
    src: url('../fonts/roboto/Roboto Black.eot');
    src: url('../fonts/roboto/Roboto Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/roboto/Roboto Black.woff2') format('woff2'),
        url('../fonts/roboto/Roboto Black.woff') format('woff'),
        url('../fonts/roboto/Roboto Black.otf') format('opentype'),
        url('../fonts/roboto/Roboto Black.svg#Roboto Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2044;
}

@font-face {
    font-family:'Open Sans Light';
    src: url('../fonts/open_sans/Open Sans Light.eot');
    src: url('../fonts/open_sans/Open Sans Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/open_sans/Open Sans Light.woff2') format('woff2'),
        url('../fonts/open_sans/Open Sans Light.woff') format('woff'),
        url('../fonts/open_sans/Open Sans Light.svg#Open Sans Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Open Sans Light Italic';
    src: url('../fonts/open_sans/Open Sans Light Italic.eot');
    src: url('../fonts/open_sans/Open Sans Light Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/open_sans/Open Sans Light Italic.woff2') format('woff2'),
        url('../fonts/open_sans/Open Sans Light Italic.woff') format('woff'),
        url('../fonts/open_sans/Open Sans Light Italic.svg#Open Sans Light Italic') format('svg');
    font-weight: 300;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2074;
}

@font-face {
    font-family:'Open Sans';
    src: url('../fonts/open_sans/Open Sans Regular.eot');
    src: url('../fonts/open_sans/Open Sans Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/open_sans/Open Sans Regular.woff2') format('woff2'),
        url('../fonts/open_sans/Open Sans Regular.woff') format('woff'),
        url('../fonts/open_sans/Open Sans Regular.svg#Open Sans Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Open Sans Bold';
    src: url('../fonts/open_sans/Open Sans Bold.eot');
    src: url('../fonts/open_sans/Open Sans Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/open_sans/Open Sans Bold.woff2') format('woff2'),
        url('../fonts/open_sans/Open Sans Bold.woff') format('woff'),
        url('../fonts/open_sans/Open Sans Bold.svg#Open Sans Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2074;
}

@font-face {
    font-family:'Open Sans ExtraBold';
    src: url('../fonts/open_sans/Open Sans ExtraBold.eot');
    src: url('../fonts/open_sans/Open Sans ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/open_sans/Open Sans ExtraBold.woff2') format('woff2'),
        url('../fonts/open_sans/Open Sans ExtraBold.woff') format('woff'),
        url('../fonts/open_sans/Open Sans ExtraBold.svg#Open Sans ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2074;
}

@font-face {
    font-family:'Alegreya Sans Light';
    src: url('../fonts/alegreya/Alegreya Sans Light.eot');
    src: url('../fonts/alegreya/Alegreya Sans Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/alegreya/Alegreya Sans Light.woff2') format('woff2'),
        url('../fonts/alegreya/Alegreya Sans Light.woff') format('woff'),
        url('../fonts/alegreya/Alegreya Sans Light.svg#Alegreya Sans Light') format('svg');
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Alegreya Sans Medium';
    src: url('../fonts/alegreya/Alegreya Sans Medium.eot');
    src: url('../fonts/alegreya/Alegreya Sans Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/alegreya/Alegreya Sans Medium.woff2') format('woff2'),
        url('../fonts/alegreya/Alegreya Sans Medium.woff') format('woff'),
        url('../fonts/alegreya/Alegreya Sans Medium.svg#Alegreya Sans Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Alegreya Sans Bold';
    src: url('../fonts/alegreya/Alegreya Sans Bold.eot');
    src: url('../fonts/alegreya/Alegreya Sans Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/alegreya/Alegreya Sans Bold.woff2') format('woff2'),
        url('../fonts/alegreya/Alegreya Sans Bold.woff') format('woff'),
        url('../fonts/alegreya/Alegreya Sans Bold.svg#Alegreya Sans Bold') format('svg');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Alegreya Sans Italic';
    src: url('../fonts/alegreya/Alegreya Sans Italic.eot');
    src: url('../fonts/alegreya/Alegreya Sans Italic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/alegreya/Alegreya Sans Italic.woff2') format('woff2'),
        url('../fonts/alegreya/Alegreya Sans Italic.woff') format('woff'),
        url('../fonts/alegreya/Alegreya Sans Italic.svg#Alegreya Sans Italic') format('svg');
    font-weight: 400;
    font-style: italic;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Alegreya Sans';
    src: url('../fonts/alegreya/Alegreya Sans Regular.eot');
    src: url('../fonts/alegreya/Alegreya Sans Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/alegreya/Alegreya Sans Regular.woff2') format('woff2'),
        url('../fonts/alegreya/Alegreya Sans Regular.woff') format('woff'),
        url('../fonts/alegreya/Alegreya Sans Regular.svg#Alegreya Sans Regular') format('svg');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}

@font-face {
    font-family:'Alegreya Sans ExtraBold';
    src: url('../fonts/alegreya/Alegreya Sans ExtraBold.eot');
    src: url('../fonts/alegreya/Alegreya Sans ExtraBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/alegreya/Alegreya Sans ExtraBold.woff2') format('woff2'),
        url('../fonts/alegreya/Alegreya Sans ExtraBold.woff') format('woff'),
        url('../fonts/alegreya/Alegreya Sans ExtraBold.svg#Alegreya Sans ExtraBold') format('svg');
    font-weight: 800;
    font-style: normal;
    font-stretch: normal;
    unicode-range: U+0020-2212;
}