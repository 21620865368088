header{
    background-color: rgba(white, 0.8);
    position: fixed;
    width: 100%;
    z-index: 50;
    .brand-logo{
        padding: 0.2rem;
        img{
            @include imag(150px);
        }
    }

    .btn-menu {
        color: $verde;
    }

    .nav-menu{
        // width: 80%;
        display: inline-block;
        // @media (max-width: 1213px) {
        //     width: 100%;
        // }
        li{
            // width: 14% !important;
            &:first-of-type {
                padding-right: 8rem;
            }
            i{
                font-size: 14px;
            }
            a{
                font-family: $open-sans-bold;
                font-size: 15px;
                color: #707176;
                text-align: center;
                padding: 0px 20px;
                &:hover {
                    color: $verde;
                    background-color: transparent;
                }
            }
            img {
                width: 100%;
                margin-top: -3px;
                vertical-align: middle;
                max-width: 150px;
            }
        }
        .redes-header {
            a {
                width: auto;
                display: inline-block;
                @media (max-width: 1213px) {
                    padding:0px 10px;
                }
            }
        }
        .active {
            background-color: transparent;
            a {
                color: $verde;
            }
        }
    }

    nav{
        background-color: transparent;
        height: 70px;
        line-height: 70px;
        @media (max-width: 1213px) {
            height: 64px;
            line-height: 64px;
        }
    }
    .nav-wrapper {
        background-color: transparent;
        text-align: center;
    }

    .side-nav{
        .search{
            margin-top: 1px;
            padding: 1px 0 0 0;
            z-index: 2;

            .search-mobile{
                width: inherit;
                margin-bottom: 0;
                padding: 20px 20px 20px 50px;
            }
            .label-icon{
                i{
                    color: rgba(117, 117, 117, 0.7);
                }
            }
        }
        li {
            a {
                font-family: $alegreya-medium;
                font-size: 18px;
                line-height: 18px;
                color: $verde;
                padding: 2rem;
                text-align: left;
            }
        }
    }
}
#sidenav-overlay {
    z-index: 49;
}
.logo-small-menu {
    width: 100px;
    vertical-align: middle;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%,-50%); /* IE 9 */
    -webkit-transform: translate(-50%,-50%); /* Chrome, Safari, Opera */
    transform: translate(-50%,-50%);
    display: none;
    @media (max-width: 992px) {
        display: block;
    }
}