.owl-item {
  transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}/*Arreglar espacio blanco entre items*/


.owl-controls {
    position: absolute!important;
    top: 49%;
    left: 0% !important;
    padding: 0 0.9375rem;
}
.owl-controls {
    width: 100% !important;
    img {
        opacity: 1;
    }
}
.owl-prev {
    width: auto;
    float: left;
}
.owl-next {
    width: auto;
    float: right;
}
.owl-dots {
  margin-top: 240px;
  text-align: center;
    .owl-dot {
        width: 2%;
        display: inline-block;
    }
}
#next {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
#prev {
  position: absolute;
  left: 1rem;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.owl-controls {
    position: absolute!important;
    top: 49%;
    left: 0% !important;
    padding: 0 0.9375rem;
}
.owl-controls {
    width: 100% !important;
    height: 1px;
    img {
        opacity: 1;
    }
}
.owl-prev {
    width: auto;
    float: left;
    img {
        width: 50px;
    }
}
.owl-next {
    width: auto;
    float: right;
    img {
        width: 50px;
    }
}
.owl-dots {
	margin-top: 240px;
	text-align: center;
    .owl-dot {
        width: 2%;
        display: inline-block;
    }
}
.owl-item {
  transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -webkit-transform: translate3d(0, 0, 0);
}/*Arreglar espacio blanco entre items (Mover de aqui y agregar esto al final del css de owl carousel) */

.owl-eventos {
    .owl-controls {
        top: calc(56% - 50px);
        @media screen and (max-width: 39.9375em) {
            top: calc(56% - 34px)
        }
        .owl-next, .owl-prev {
            // background-color: rgba(black, 0.5);
            // padding: 1rem;
            img {
                vertical-align: middle;
                @media screen and (max-width: 39.9375em) {
                    width: 24px;
                }
            }
        }
        .owl-next {
            position: relative;
            right: -60px;
            @media screen and (max-width: 39.9375em) {
                right: -11px;
            }
            @media screen and (max-width: 376px) {
                right: -20px;
            }
        }
        .owl-prev {
            position: relative;
            left: -60px;
            @media screen and (max-width: 39.9375em) {
                left: -11px;
            }
            @media screen and (max-width: 376px) {
                left: -20px;
            }
        }
    }
}

.owl-home .owl-video-tn {
  position: relative;
  height: 0;
 background-size: cover;
  padding-bottom: 44.25%;
  overflow: hidden; }

.maxh {max-height: 100vh;}