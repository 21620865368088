/* line 8, ../scss/async.scss */
.video-container {
  background: #ffffff;
  // width: 70% !important;
  // margin: 0 auto;
}

/* line 11, ../scss/async.scss */
.videoloader, .video-container {
  width: 100%;
  height: 100%;
  display: block;
  line-height: 0;
  position: relative;
  overflow: hidden;
  top: 0;
  left: 0;
  padding-bottom: 44%;
  text-align: center;
}

/* line 23, ../scss/async.scss */
.video-loader {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

/* line 30, ../scss/async.scss */
.video-play {
  -moz-border-radius: 6px;
  -webkit-border-radius: 6px;
  border-radius: 6px;
  -moz-transition-property: opacity, background-color;
  -o-transition-property: opacity, background-color;
  -webkit-transition-property: opacity, background-color;
  transition-property: opacity, background-color;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  overflow: hidden;
  text-indent: -9999px;
  z-index: 10;
  /*width: 65px;*/
  /*height: 40px;*/
  width: 108px;
  height: 108px;
  position: absolute;
  top: 43%;
  left: 50%;
  margin-left: -33px;
  margin-top: -20px;
  /*background-color: rgba(35, 31, 32, 0.85);*/
  background-image: url(../images/frontend/video_controls.png);
  background-image: url(../css/owl.video.play.png);
  background-repeat: no-repeat;
  background-position: top left;
}
/* line 53, ../scss/async.scss */
.video-play:hover {
  /*background-color: #444041;*/
}
